@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import 'bootstrap/dist/css/bootstrap.min.css';
@import "animate.css/animate.min.css";

body {
    margin: 0;
    /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
    /*'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
    /*sans-serif;*/
    font-family: 'Poppins';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    text-decoration: none !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/*section {*/
/*    min-height: 100vh;*/
/*}*/

.active {
    color: #F9F9FA;
    background: linear-gradient(262.82deg, #4A0B82 0%, #875DD2 100%);
    border-radius: 12px;
}

.col-lg-4:nth-last-child(-n+3) > .PastClientBlockItem_clientBlock__xmYt5 {
    min-height: 190px;
}

.css-692fgp {
    padding: 0 !important;
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 24px !important;
    line-height: 36px !important;
    letter-spacing: 0.75px !important;
    color: #4A0B82 !important;
    background: none !important;

}

.css-vcfpfv {
    background: #E6E4EB !important;
    outline: 8px solid #F9F9FC !important;
    transform: rotate(0deg) !important;
    border-radius: 50%;
}

.css-7mkrez {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 28px !important;
    letter-spacing: 0.75px !important;
    color: #170329 !important;
}

.css-1b3lipl:before {
    background-color: #E6E4EB !important;
    width: 1px !important;
}

.faq-row-wrapper {
    background: none !important;
}

.row-title {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 28px !important;
    letter-spacing: 0.75px !important;
    color: #170329 !important;
}

.row-content-text {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 28px !important;
    letter-spacing: 0.55px !important;
    color: #5B4D68 !important;
}

.styles_faq-row-wrapper__3vA1D .styles_faq-row__2YF3c {
    border-bottom: 1px solid #E6E4EB !important;
}

.radioInput {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.radioInput label {
    margin-left: 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.55px;
    color: #170329;
}

.btnNavigationQuiz{
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 0;
    bottom: 0;
}

.btnNavigationQuiz .btnNavigationQuiz__item{
    display: flex;

}

.btnNavigationQuiz button{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.25px;
    color: #000000;
    margin: 0 12px;
}

.modal-body{
    padding: 32px;
    background: #FFFFFF;
    border-radius: 32px;
}

.modal-content{
    background: none;
    border: none;
}

.modal-body{
    flex-direction: row-reverse;
    display: flex;
    background: none;
    align-items: flex-start;
}

input[type="radio"]{
    display: none;
}
input[type="radio"] + label{
    position: relative;
    display: inline-block;
    padding-left: 1.5em;
    cursor: pointer;
    line-height: 1em;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    margin: 4px 0;
}
input[type="radio"] + label:before,
input[type="radio"] + label:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 1.2em;
    height: 1.2em;
    text-align: center;
    color: white;
    font-family: Times;
    border-radius: 50%;
    border: 2px solid #4A0B82;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}
input[type="radio"] + label:before {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    box-shadow: inset 0 0 0 0.2em white, inset 0 0 0 1em white;
}
input[type="radio"] + label:hover:before {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    box-shadow: inset 0 0 0 0.3em white, inset 0 0 0 1em #c6c6c6;
}
input[type="radio"]:checked + label:before {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    box-shadow: inset 0 0 0 0.2em white, inset 0 0 0 1em #4A0B82;
}

div.rsw_3G > div{
    position: relative;
    min-height: 300px;
}


@media (max-width: 926px) {
    html,
    body{
        overflow-x: hidden!important;
    }
    .css-1b3lipl:before,
    .css-vcfpfv,
    .css-1jv1kel{
        left: 1em;
    }

    .formQuiz{
        max-width: 100%;
    }
    .modal-content{
        width: 100vw;
    }
    .modal-body{
        padding: 16px;
    }
}

@media (min-width: 768px) and (max-width: 926px)  {
    .col-lg-4:nth-last-child(-n+3) > .PastClientBlockItem_clientBlock__xmYt5 {
        min-height: 360px;
    }
}


@media (min-width: 1024px) and (max-width: 1200px)  {
    .col-lg-4:nth-last-child(-n+3) > .PastClientBlockItem_clientBlock__xmYt5 {
        min-height: 490px;
    }
}

@media (min-width: 1201px) and (max-width: 1400px)  {
    .col-lg-4:nth-last-child(-n+3) > .PastClientBlockItem_clientBlock__xmYt5 {
        min-height: 350px;
    }
}

@media (width: 1440px){
    .col-lg-4:nth-last-child(-n+3) > .PastClientBlockItem_clientBlock__xmYt5 {
        min-height: 330px;
    }
}

body.modal-open > :not(.modal) {
    -webkit-filter: blur(1px);
    -moz-filter: blur(1px);
    -o-filter: blur(1px);
    -ms-filter: blur(1px);
    filter: blur(1px);
}


