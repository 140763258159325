.infoBlock{
    margin-bottom: 72px;
    position: relative;
    z-index: 1;
}

.fadeInBottom {
    -webkit-animation: fade-in-bottom 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in-bottom 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.Modelyst{
    padding: 60px 0;
}

.Modelyst .infoBlock h2,
.Modelyst .infoBlockDataManagement h2,
.Modelyst .infoBlockUniqueEnvironment h2{
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.75px;
    color: #170329;
}

.Modelyst .infoBlock p,
.Modelyst .infoBlockDataManagement p,
.Modelyst .infoBlockUniqueEnvironment p{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.55px;
    color: #5B4D68;

}


@media (max-width: 926px) {
    .Modelyst {
        padding: 60px 0;
        z-index: 1;
        position: relative;
    }
}

.Modelyst .infoBlockDataManagement p{
    margin-bottom: 0px;
}

.Modelyst .infoBlockUniqueEnvironment p a{
    color: #4A0B82;
    text-decoration: underline!important;
}

.infoBlockItem{
    margin-top: 40px;
}

.infoBlockUniqueEnvironment{
    margin-top: 72px;
}

.mobileName{
    font-style: normal!important;
    font-weight: 500!important;
    font-size: 32px!important;
    line-height: 36px!important;
    letter-spacing: 1px!important;
    color: #4A0B82!important;
    margin-bottom: 24px;
}
