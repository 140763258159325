.footer {
    padding: 0 60px;
}

.footerItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.linkFooter {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.25px;
    color: #F9F9FA;
    margin-right: 52px;
    cursor: pointer;
}

.footerNav a:last-child,
.footerAfter a:last-child {
    margin-right: 0px;
}

.footerAfter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;
}

.footerAfter a {
    margin-right: 52px;
}

.footerAfter span,
.footerAfter a {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.25px;
    color: #5B4D68;
}

@media (max-width: 926px) {
    .footer {
        padding: 0 12px;
    }

    .footerItem {
        flex-direction: column;
        align-items: flex-start;
    }

    .footerNav {
        margin-top: 24px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
    }

    .footerAfter {
        flex-direction: column-reverse;
        justify-content: center;
    }

    .footerAfter > div {
        margin: 12px 0;
    }

}
