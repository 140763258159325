.mobileMenu{
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.mobileNavigation,
.mobileMenuItem{
    z-index: 3;
}

.mobileMenuItem a{

}

.mobileMenuLink{
    overflow: hidden!important;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    z-index: 2;
    background-color: #F9F9FC;
    background-image: url("/src/assets/img/background-menu.jpg");
    background-size: 100%;
    background-repeat: no-repeat;
    padding: 70px 16px 0 16px;
    display: flex;
    flex-direction: column;
}

.mobileMenuLinkItem{
    display: flex;
    flex-direction: column;
    flex-grow: 4;
}

.mobileMenuLinkItem a:first-child{
    margin-top: 50px;
}

.mobileMenuLinkItem a {
    margin: 16px 0;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #170329;
}

.mobileMenuLinkContact{
    flex-grow: 1;
    display: flex;
    justify-content: center;
}

.mobileMenuLinkContact a{
    display: flex;
    justify-content: center;
    background: linear-gradient(262.82deg, #4A0B82 0%, #875DD2 100%);
    border-radius: 12px;
    padding: 12px 16px;
    height: 44px;
    font-style: normal;
    font-weight: 500!important;
    font-size: 14px!important;
    line-height: 20px!important;
    letter-spacing: 0.75px!important;
    color: #F9F9FA!important;
    width: 100%;
}

.fixedMenu{
    position: fixed;
    z-index: 3;
    /*padding: 0 16px 0 0;*/
    width: 100%;
    left: 0;
    background-image: url("/src/assets/img/background-menu.jpg");
    background-size: 100%;
    padding:  0 16px 16px;
    background-color: #F9F9FC;
}

.fixedMenu .mobileNavigation{
    padding-top: 16px;
}
