.faqBlock{
    padding: 60px 0;
}

.faqBlock h2{
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.75px;
    color: #170329;
    margin-bottom: 16px;
}

.mobileName{
    font-style: normal!important;
    font-weight: 500!important;
    font-size: 32px!important;
    line-height: 36px!important;
    letter-spacing: 1px!important;
    color: #4A0B82!important;
}
