.sideMenu > ul,
.sideMenuMain > ul {
    padding: 0;
}

.sideMenu > ul > li,
.sideMenuMain > ul > li {
    list-style-type: none;
}


.activeLink {
    font-style: normal;
    font-weight: 500 !important;
    font-size: 24px !important;
    line-height: 36px !important;
    letter-spacing: 0.75px;
    color: #4A0B82!important;
    padding: 8px 0;
}

.sideMenu > ul > li > a,
.sideMenuMain > ul > li > a {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.55px;
    /*color: #ABA2BE;*/
    cursor: pointer;
}


.sideMenu,
.sideMenuMain{
    padding-top: 60px;
    display: flex;
    flex-direction: column;
}

.sideMenuMain,
.sideMenu {
    padding-top: 60px;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    width: 200px;
    margin-right: 112px;
}

.sideMenuMain{
    position: sticky;
    position: -webkit-sticky;
}

.logo {
    margin-bottom: 32px;
    cursor: pointer;
}

.darkLink {
    color: rgba(255, 255, 255, 0.3);
}


@media (max-width: 926px) {
    .sideMenu,
    .sideMenuMain {
        display: none;
    }
}
