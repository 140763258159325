.clientBlock {
    padding: 24px;
    border-radius: 12px;
    min-height: 190px!important;
    margin-bottom: 24px;
    background: #FFFFFF;
    border: 1px solid #F3F1F5;
    box-shadow: 0px 2px 60px rgba(23, 3, 41, 0.05);
}

.clientBlock p {
    margin-top: 24px;
    font-style: normal!important;
    font-weight: 500!important;
    font-size: 16px!important;
    line-height: 24px!important;
    letter-spacing: 0.75px!important;
    color: #170329!important;
    margin-bottom: 0;
}

@media (max-width: 926px) {
    .clientBlock{
        background: #fff;
    }
    .clientBlock p{
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.75px;
        color: #170329;
    }
}

@media (min-width: 1024px) and (max-width: 1300px)  {
    .clientBlock{
        min-height: 300px!important;
    }
}


@media (min-width: 1301px) and (max-width: 1500px){
    .clientBlock{
        min-height: 208px!important;
    }
}

@media (min-width: 768px) and (max-width: 926px)  {
    .clientBlock{
        min-height: 230px!important;
    }
}
