.App{
    background: #170329;
}

.infoBlock,
.infoBlockDark{
    display: grid;
    grid-template-columns: 2fr 10fr;
    padding: 0 60px;
    background: #F9F9FC;
    position: relative;
    z-index: 1;
}

.infoBlockDark{
    background: #170329
}

.infoBlockAfter{
    border-radius: 0px 0px 32px 32px
}

@media (max-width: 926px) {
    .infoBlock,
    .infoBlockDark,
    .infoBlockAfter{
        grid-template-columns: 12fr!important;
        padding: 0 16px!important;
    }
    .infoBlockDark{
        background: #F9F9FC;
    }

}
