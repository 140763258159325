.contactUs{
    padding: 60px 0;
    min-height: 100vh;
}

.contactUs h2{
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.75px;
    color: #170329;
    margin-bottom: 12px;
}

.contactUs p{
    margin-top: 40px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.55px;
    color: #170329;
}

.contactUs p a{
    text-decoration: underline!important;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.25px;
    color: #4A0B82;
}

.mobileName{
    font-style: normal!important;
    font-weight: 500!important;
    font-size: 32px!important;
    line-height: 36px!important;
    letter-spacing: 1px!important;
    color: #4A0B82!important;
}
