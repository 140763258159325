.modalWindow {
    border-radius: 32px;
    padding: 32px;
}

.teamImg {
    width: 100%;
    border-radius: 32px;
    margin: 16px 0;
}

.modalContent{
    background: #fff;
    border-radius: 32px;
    padding: 32px;
}

.modalContent h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 52px;
    letter-spacing: 1px;
    color: #170329;
}

.modalContent h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.75px;
    color: #170329;
}

.modalContent p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.55px;
    color: #5B4D68;
    margin: 12px 0 4px 0;
}

.prediction {
    font-weight: 500 !important;
    color: #4A0B82 !important;
}

.learnMoreCases {
    margin-bottom: 64px;
    padding: 24px 32px;
    background: #F7F7F8;
    border: 1px solid #F3F1F5;
    border-radius: 12px;
}

.learnMoreCasesItem {
    display: flex;
    justify-content: flex-end;
}

.learnMoreCasesItem a,
.questions a {
    font-style: normal;
    font-weight: 500;
    font-size: 16px!important;
    line-height: 28px;
    letter-spacing: 0.25px;
    color: #4A0B82!important;
    cursor: pointer;
}

.learnMoreCasesItem a:hover > img,
.questions  a:hover > img{
    transform: rotate(315deg);
}


.learnMoreCasesItem a img,
.questions a img {
    margin-left: 14px;
}

.questions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    background: #F7F7F8;
    border-radius: 16px;
}

.questions h2 {
    margin: 0;
}

.navModal {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: 8px;
}

.navModal button{
    background: #fff;
    border-radius: 50px;
    padding: 2px;
}

.navModal button:first-child{
    margin-bottom: 5px;
}

@media (max-width: 926px) {
    .modalContent h1{
        font-size: 30px;
        line-height: 32px;
    }
    .modalWindow{
        padding: 0;
    }
}
