.aboutUs {
    padding: 60px 0;
}

.ourVision h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.75px;
    color: #170329;
}

.ourVision p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.55px;
    color: #5B4D68;
}

.ourStory h2{
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.75px;
    color: #170329;
}

.ourStory{
    margin-top: 72px;
}

@media (max-width: 926px) {
    .mobileName {
        font-style: normal!important;
        font-weight: 500!important;
        font-size: 32px!important;
        line-height: 36px!important;
        letter-spacing: 1px!important;
        color: #4A0B82!important;
        margin-bottom: 24px;
    }
}

