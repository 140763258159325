.peopleCardItem{
    padding: 24px;
    background: #FFFFFF;
    border: 1px solid #F3F1F5;
    box-shadow: 0px 2px 60px rgba(23, 3, 41, 0.05);
    border-radius: 12px;
    min-height: 630px;
}

.peopleCardItem img{
    width: 100%;
    border-radius: 8px;
}

.peopleCardItem h3{
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.75px;
    color: #170329;
    margin: 16px 0;
}

.peopleCardItem p{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.25px;
    color: #5B4D68;
    margin-bottom: 0;
}

@media (max-width: 926px) {
    .peopleCardItem{
        min-height: auto;
        margin: 12px 0;
    }
}

@media (min-width: 1024px) and (max-width: 1500px)  {
    .peopleCardItem{
        min-height: 560px;
    }
}
