.headerNavbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 32px 0;
    z-index: 1;
}

.headerNavbarNavigation>a {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.25px;
    color: #170329;
    padding: 8px 0;
    margin: 0 52px 0 0;
}

.iconButton {
    margin: 0;
    color: #F9F9FA !important;
    background: linear-gradient(262.82deg, #4A0B82 0%, #875DD2 100%);
    border-radius: 12px;
    padding: 8px 16px;
    cursor: pointer;
}

.headerNavbarNavigation>a:last-child {
    margin: 0;
    color: #F9F9FA !important;
    background: linear-gradient(262.82deg, #4A0B82 0%, #875DD2 100%);
    border-radius: 12px;
    padding: 8px 16px;
    cursor: pointer;
}

.headerNavbarNavigation>a:not(:last-child):hover {

    background-image: url('/src/assets/img/ellipse.jpg');
    background-repeat: no-repeat;
    background-size: 6px 6px;
    background-position: bottom center;
    cursor: pointer;
}

@media (max-width: 926px) {
    .headerNavbar {
        display: none;
    }
}