.form{
    max-width: 472px;
}

.formName,
.formEmail,
.formMessage{
    margin-bottom: 16px;
}

.formMessage textarea{
    min-height: 96px;
}

.formName input,
.formEmail input,
.formMessage textarea{
    padding: 4px 16px;
    min-width: 472px;
    background: #FFF;
    border-radius: 15px;
    border: none;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.75px;
    color: rgba(0, 0, 0, 0.8);
}

.formBtn{
    display: flex;
    justify-content: flex-end;
}

.formBtn button{
    max-width: 153px;
    padding: 8px 16px;
    color: #FFF;
    border-radius: 12px;
    background: #4A0B82;
}

.messageInfo{
    font-weight: 500;
    color: #4A0B82;
}

@media (max-width: 926px) {
    .form{
        max-width: 100%;
    }
    .formName input,
    .formEmail input,
    .formMessage textarea{
        min-width: 100%;
    }

}

