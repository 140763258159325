.mainBlock {
    background-color: #F9F9FC;
    background-size: 100%;
    background-repeat: no-repeat;
    padding: 0 60px;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
    width: 100%;
}

.particlesContainer {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.particlesContainer > div {
    height: 100%;
    width: 100%;
}

.mainBlock h1{
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 5rem;
    line-height: 96px;
    letter-spacing: 0.25px;
    color: #170329;
    text-align: center;
    margin-bottom: 110px;
    flex-grow: 1;
    z-index: 1;
    pointer-events: none
}

.scrollDown {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
}


.shakeVertical {
    -webkit-animation: shake-vertical 10s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
    animation: shake-vertical 10s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-9-10 20:39:36
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation shake-vertical
 * ----------------------------------------
 */
@-webkit-keyframes shakeVertical {

    0%,
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    10%,
    30%,
    50%,
    70% {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px);
    }

    20%,
    40%,
    60% {
        -webkit-transform: translateY(8px);
        transform: translateY(8px);
    }

    80% {
        -webkit-transform: translateY(6.4px);
        transform: translateY(6.4px);
    }

    90% {
        -webkit-transform: translateY(-6.4px);
        transform: translateY(-6.4px);
    }
}

@keyframes shake-vertical {

    0%,
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    10%,
    30%,
    50%,
    70% {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px);
    }

    20%,
    40%,
    60% {
        -webkit-transform: translateY(8px);
        transform: translateY(8px);
    }

    80% {
        -webkit-transform: translateY(6.4px);
        transform: translateY(6.4px);
    }

    90% {
        -webkit-transform: translateY(-6.4px);
        transform: translateY(-6.4px);
    }
}



.scrollDown:hover {
    cursor: pointer;
}

.scrollDown span {
    margin-top: 6px;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #5B4D68;

}

#tsparticles {
    width: 100%;
}

.webgl {
    pointer-events: none !important;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    outline: none;
    min-height: 100vh;
    width: 100%;
    image-rendering: auto;
}

@media (max-width: 926px) {
    .mainBlock {
        padding: 0 16px;
        /* background-image: url("./../../../assets/img/background-mobile.jpg"); */
        min-height: 100vh;

    }

    .mainBlock h1 {
        font-style: normal;
        font-weight: 500 !important;
        font-size: 34px !important;
        line-height: 40px !important;
        letter-spacing: 0.25px !important;
        text-align: center;
        margin-top: 50px;
    }

    .scrollDown {
        display: none;
    }

    .webgl {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: -10;
        outline: none;
        width: 100%;
        pointer-events: none !important;
    }

}