.block {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 32px;
    background: #FFFFFF;
    border: 1px solid #F3F1F5;
    box-shadow: 0px 2px 60px rgba(23, 3, 41, 0.05);
    border-radius: 12px;
    min-height: 280px;
}

.blockItem{
    flex-grow: 1;
}

.blockItem > div{
    text-align: center;
}

.blockItem > div > img{
    margin-bottom: 32px;
}

.blockItemName{
    font-style: normal!important;
    font-weight: 600!important;
    font-size: 16px!important;
    line-height: 24px!important;
    letter-spacing: 0.75px!important;
    color: #170329!important;
}

.blockItemDescription{
    font-style: normal!important;
    font-weight: 400!important;
    font-size: 14px!important;
    line-height: 24px!important;
    letter-spacing: 0.25px!important;
    color: #ABA2BE!important;
}

.blockSources{
    width: 100%;
    text-align: right;
}

.blockSources a{
    padding: 8px 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.25px;
    color: #4A0B82;
}

.blockSources a:hover{
    padding: 8px 16px;
    background: #F3F1F5;
    border-radius: 1000px;
}

.blockSources a:hover > img{
    transform: rotate(315deg);
}


.blockSources img{
    margin-left: 14px;
}

@media (min-width: 1240px) {
    .block{
        min-height: 280px;
    }
}

@media (max-width: 926px) {
    .block{
        margin: 8px 0!important;
    }
}

@media (min-width: 1024px) and (max-width: 1500px)  {
    .block{
        min-height: 280px;
    }
}

@media (min-width: 768px) and (max-width: 926px)  {
    .block{
        min-height:280px;
    }
}
