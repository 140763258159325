.caseStudies{
    padding: 60px 0;
    min-height: auto;
}

.mobileName{
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 36px;
    letter-spacing: 1px;
    color: #4A0B82;
    margin-bottom: 24px;
}
