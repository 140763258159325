.whatWeDoBlock {
    padding: 60px 0;
}

.data,
.work {
    margin-bottom: 72px;
}

.data h2,
.work h2,
.client h2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.75px;
    color: #170329;
}

.data p,
.work p,
.client p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.55px;
    color: #5B4D68;
}

.quote {
    padding: 32px;
    background: rgba(57, 54, 77, 0.7);
    box-shadow: 0px 2px 60px rgba(23, 3, 41, 0.05);
    border-radius: 12px;
    margin-top: 72px;
}

.name {
    display: grid;
    grid-template-columns: 2fr 10fr;
}

.name p {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.75px;
    color: #F9F9FA;
    margin-left: 8px;
}

.name span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.55px;
    color: #895BE5;
}

.quoteItem p {
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.55px;
    color: #F9F9FA;
    margin: 0;
}

.models {
    margin-top: 72px;
}

.models h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.75px;
    color: #170329;
    margin-bottom: 16px;
}

.modelsBtn,
.learnBtn {
    display: flex;
    margin-top: 32px;
    align-items: center;
    justify-content: flex-end;
}

.modelsBtn a,
.learn a {
    font-style: normal;
    font-weight: 500!important;
    font-size: 16px!important;
    line-height: 28px;
    letter-spacing: 0.25px;
    color: #4A0B82;
}

.modelsBtn a,
.learnBtn a{
    padding: 8px 16px;
}

.modelsBtn a:hover,
.learnBtn a:hover{
    padding: 8px 16px;
    background: #F3F1F5;
    border-radius: 1000px;
}

.modelsBtn a:hover > img,
.learnBtn a:hover > img{
    transform: rotate(315deg);
}

.modelsBtn a img,
.learn a img {
    margin-left: 14px;
}


.learn {
    margin-top: 80px;
    padding: 24px 32px;
    border-radius: 12px;
    background: #FFFFFF;
    border: 1px solid #F3F1F5;
    box-shadow: 0px 2px 60px rgba(23, 3, 41, 0.05);

}

.learn p {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.75px;
    color: #170329;

}

.mobileName{
    font-style: normal!important;
    font-weight: 500!important;
    font-size: 32px!important;
    line-height: 36px!important;
    letter-spacing: 1px!important;
    color: #4A0B82!important;

}
@media (max-width: 926px) {
    .data h2,
    .work h2,
    .client h2{
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: 0.75px;
        color: #170329;
    }
    .data p,
    .work p{
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.75px;
        color: #5B4D68;
    }
    .models h2,
    .learn p{
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: 0.75px;
        color: #170329;
    }

    .modelsBtn a,
    .learnBtn a{
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.75px;
        color: #4A0B82;
    }

    .learn{
        background: #FFF;
    }

}

.automation h3,
.machine h3,
.userInterfaces h3{
    margin: 24px 0 12px 0;
    font-style: normal!important;
    font-weight: 600!important;
    font-size: 18px!important;
    line-height: 32px!important;
    letter-spacing: 0.75px!important;
    color: #170329!important;
}

