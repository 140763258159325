.quote,
.quoteLight{
    padding: 32px;
    background: rgba(57, 54, 77, 0.7);
    box-shadow: 0px 2px 60px rgba(23, 3, 41, 0.05);
    border-radius: 12px;
    margin-top: 72px;
}

.quoteLight{
    background: #FFFFFF;
}

.quoteLight .name p,
.quoteLight .quoteItem p{
    color: #170329;
}

.name{
    display: grid;
    grid-template-columns: 2fr 10fr;
    margin-right: 46px;
}

.name p{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.75px;
    color: #F9F9FA;
    margin-left: 14px;
}

.name span{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.55px;
    color: #895BE5;
}

.quoteItem p{
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.55px;
    color: #F9F9FA;
    margin: 0;
}
