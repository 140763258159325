button {
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
}

.activeTab {
    background: #4A0B82;
    border-radius: 12px;
    padding: 8px 16px;
    width: 149px;
    height: 44px;
    margin-right: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.25px;
    color: #FFFFFF;

}

.defaultTab {
    padding: 8px 16px;
    width: 149px;
    height: 44px;
    margin-right: 8px;
    color: #5B4D68;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.25px;
    background: #E6E4EB;
    border-radius: 12px;
}

.defaultTab:hover{
    color: #170329;
    background: #CAC5D5;
    padding: 8px 16px;
}

.tabContent{
    margin-top: 40px;
}
