.caseItem{
    padding: 25px 32px;
    background: #FFFFFF;
    border: 1px solid #F3F1F5;
    box-shadow: 0px 2px 60px rgba(23, 3, 41, 0.05);
    border-radius: 12px;
    text-align: center;
    min-height: 240px!important;
    display: flex;
    flex-direction: column;
}

.caseItem h3{
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0.75px;
    color: #170329;
    text-align: left;
    margin-top: 24px;
    min-height: 90px;
    flex-grow: 1;
}

.caseItem img{
    margin: 0 auto;
}

.caseItemBtn{
    margin-top: 32px;
    text-align: right;
}

.caseItemBtn a{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.25px;
    color: #4A0B82!important;
    cursor: pointer;
    padding: 8px 16px;
}

.caseItemBtn a:hover{
    padding: 8px 16px;
    background: #F3F1F5;
    border-radius: 1000px;
}

.caseItemBtn a:hover > img{
    transform: rotate(315deg);
}

.caseItemBtn img{
    margin-left: 14px;
}

@media (max-width: 926px) {
    .caseItem{
        margin: 12px 0;
    }
}


@media (min-width: 1240px) {
    .caseItem{
        min-height: 400px;
    }
}

@media (min-width: 1024px) and (max-width: 1300px)  {
    .caseItem{
        min-height: 460px;
    }
}


@media (min-width: 1301px) and (max-width: 1500px){
    .caseItem{
        min-height: 460px;
    }
}

@media (min-width: 768px) and (max-width: 926px)  {
    .caseItem{
        min-height: 460px;
    }
}
