.block{
    display: flex;
    flex-direction: column;
    padding: 24px;
    background: #FFFFFF;
    border: 1px solid #F3F1F5;
    box-shadow: 0px 2px 60px rgba(23, 3, 41, 0.05);
    border-radius: 12px;
    margin-bottom: 12px;
}

.block h3{
    font-style: normal!important;
    font-weight: 600!important;
    font-size: 16px!important;
    line-height: 24px!important;
    letter-spacing: 0.75px!important;
    color: #170329!important;
}

.block p{
    font-style: normal!important;
    font-weight: 400!important;
    font-size: 14px!important;
    line-height: 24px!important;
    letter-spacing: 0.25px!important;
    color: #5B4D68!important;
}

@media (max-width: 926px) {
    .block{
        margin: 16px 0!important;
    }
}
